<template>
  <component
    :is="fieldComponent"
    v-if="shouldRender"
    class="w-full body-font field-component"
    :question="fieldWithChanges"
    :value="fieldValue"
    :document-value="documentValue"
    :entity-type="entityType"
    :config="fieldWithChanges.config"
    :questions="nestedFields"
    :path="path"
  />
</template>

<script>
import _get from 'lodash/get'
import { computed, toRefs, ref, inject } from '@nuxtjs/composition-api'
import { getAll } from '@/utils/object'
import matchFieldType from '@/utils/matchFieldTypes'
import { calculateDerivedValue } from '@/utils/derivedValueUtils'
import { isValueEmpty, ASYNC_DATA_QUESTION, ALWAYS_RENDER_QUESTION_TYPES } from '@/utils/generalUtils'
import useFieldActions from '@/composables/useFieldActions'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { HIDE_EMPTY_FIELDS, LEARNING_OUTCOME_V2 } from '@/constants/featureFlags'

export default {
  name: 'DynamicLayoutField',
  props: {
    questionId: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    documentValue: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line
    value: {
      required: true,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { documentValue, questionId, value } = toRefs(props)
    const rootLevelFields = inject('questions', [])

    const forceHideFieldsWhenValueEmpty = inject(
      'forceHideFieldsWhenValueEmpty',
      false
    )

    const { hideEmptyFields, enableLearningOutcomeV2 } = useFeatureFlags({
      hideEmptyFields: HIDE_EMPTY_FIELDS,
      enableLearningOutcomeV2: LEARNING_OUTCOME_V2,
    })

    const field = computed(
      () => findQuestionInArray(props.questions, props.questionId) || {}
    )
    const fullPath = [...props.path, field.value.dataKey]
    const rootLevelField = computed(
      () => findQuestionInArray(rootLevelFields, fullPath[0]) || field.value
    )

    const localValue = ref()
    const fieldValue = computed(() => {
      if (localValue.value !== undefined) return localValue.value

      const derivedValue =
        field.value.questionType === 'derived'
          ? calculateDerivedValue(field.value?.config?.value, props.value)
          : ''

      if (derivedValue) {
        return derivedValue
      }

      if (rootLevelField.value.custom) {
        const originalDocumentValue = _get(
          documentValue.value.customFields || {},
          fullPath
        ) || field.value.config?.default?.value
        if (!originalDocumentValue && !!value.value && !!questionId.value) {
          const childValue = (value.value || {})[questionId.value]
          if (!childValue)
            return (value.value.customFields || {})[questionId.value]
          return childValue
        }
        return originalDocumentValue
      }

      if (props.value) {
        const key =
          field.value.dataKey || field.value.questionId || field.value.key
        const valueFromNested = key?.includes('*')
          ? getAll(props.value, [key])
          : _get(props.value, key)
        if (valueFromNested !== undefined) return valueFromNested
      }
      const valueFromDocument =
        props.documentValue[
          field.value.dataKey || field.value.questionId || field.value.key
        ]
      if (valueFromDocument !== undefined) return valueFromDocument
      return (
        field.value.config &&
        field.value.config.default &&
        field.value.config.default.value
      )
    })
    const onActionUpdate = (value) => {
      localValue.value = value
    }

    const { fieldWithChanges } = useFieldActions({
      field,
      formValue: documentValue,
      fieldValue,
      update: onActionUpdate,
      questions: props.questions,
    })

    const isVisible = computed(() => !fieldWithChanges.value.hidden)
    const nestedFields = computed(
      () =>
        fieldWithChanges.value.fields ||
        (fieldWithChanges.value.config && fieldWithChanges.value.config.fields)
    )

    const fieldComponent = matchFieldType(
      fieldWithChanges.value.questionType,
      nestedFields.value
    )

    const isAsync = computed(() => {
      return ASYNC_DATA_QUESTION.includes(fieldWithChanges.value.questionType)
    })

    const isAlwaysRender = computed(() => {
      return ALWAYS_RENDER_QUESTION_TYPES.includes(fieldWithChanges.value.questionType)
    })

    const hasValue = computed(() => {
      if (field.value.questionType === 'documentItemSelect') return true
      if (
        (field.value.dataKey || field.value.questionId || field.value.key) ===
        'dependencies'
      ) {
        const courseDependents = props.documentValue.courseDependents || []
        const programDependents = props.documentValue.programDependents || []
        return !isValueEmpty([...courseDependents, ...programDependents])
      }
      return !isValueEmpty(fieldValue.value)
    })

    const isCorrectVersionOfLearningOutcomeQuestion = computed(() => {
      const isLearningOutcomeQuestion = fieldWithChanges.value.questionId === 'sy_course_learningOutcomes'
      const isLearningOutcomeV2Question = fieldWithChanges.value.questionId === 'sy_course_learningOutcomesV2'
      if (!isLearningOutcomeQuestion && !isLearningOutcomeV2Question) return null
      const isCorrectVersion = enableLearningOutcomeV2 ? isLearningOutcomeV2Question : isLearningOutcomeQuestion
      return isCorrectVersion
    })

    const shouldRender = computed(() => {
      if (isCorrectVersionOfLearningOutcomeQuestion.value === false) return false
      return hideEmptyFields || forceHideFieldsWhenValueEmpty
        ? // for questions having their data loaded asynchronously there's no value to be checked
          // so those have to determine whether to render or not, on their own
          isVisible.value && (isAsync.value || hasValue.value || isAlwaysRender.value)
        : isVisible.value
    })

    return {
      fieldValue,
      fieldWithChanges,
      isVisible,
      nestedFields,
      fieldComponent,
      shouldRender,
    }
  },
}

function findQuestionInArray(collection, questionId) {
  return collection.find(
    (q) =>
      q.questionId === questionId ||
      q.dataKey === questionId ||
      q.key === questionId
  )
}
</script>

<style lang="css">
/* heading-3 style same as rich content style */
.field-component .field-label {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: bold;
}
.field-component .field-value {
  font-size: 16px;
  line-height: 160%;
  color: var(--textFontColor, inherit);
  font-weight: var(--textFontWeight, inherit);
}
</style>
